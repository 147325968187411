/* eslint-disable no-restricted-globals */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import $ from 'jquery'
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/* =========================== */
/*  SET THEME COLORS HERE      */
/* =========================== */
var theme = {
  "accent1": "#4ecdc4",
  "accent1_dark": "#292c35"
}


/* ============================ */
/*           SCRIPTING          */
/* ============================ */
$(document).ready(function () {

  //  Variable initializing
  // =========================== //
  var last_known_scroll_position = 0;
  var ticking = false;
  var screenHeight = $(".nav").height(); //window.innerHeight;    
  var navContainer = $("#nav-container");
  var nav = $("#nav");

  //  Mouse wheel scrolling
  // =========================== //
  function mouseWheelScroll(scroll_pos) {

      var new_pos = 'right ' + (0 - parseInt(scroll_pos) * .3).toString() + 'px';
      var nav_bg = scroll_pos >= screenHeight? theme["accent1_dark"] : 'transparent';

      navContainer.css('background-color', nav_bg);
      nav.css('background-position', new_pos);
  }
  
  window.addEventListener('scroll', function(e) {
      last_known_scroll_position = window.scrollY;
      if (!ticking) {
          window.requestAnimationFrame(function() {
              mouseWheelScroll(last_known_scroll_position);
              ticking = false;
          });     
          
          ticking = true;
      }
  });

  // Link scrolling
  // =========================== //
  // Select all links with hashes
  $('a[href*="#"]')
  // Remove links that don't actually link to anything

  .not('[href="#"]')
  .not('[href="#0"]')
  .click(function(event) {
      // On-page links
      if (
          location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && 
          location.hostname === this.hostname
      ) {
          // Figure out element to scroll to
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          // Does a scroll target exist?
          if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate({
                  scrollTop: target.offset().top
              }, 1000, function() {
                  // Callback after animation
                  // Must change focus! This is for accesibility for users who only use keyboard
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                      return false;
                  } else {
                      $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                      $target.focus(); // Set focus again
                  };
              });
          }
      }
  });  
  
});