import React from 'react';

/* =========================== */
/*  SET THEME COLORS HERE      */
/* =========================== */
var theme = {
  "accent1": "#4ecdc4",
  "accent1_dark": "#292c35"
}

/* ============================ */
/*     SET NAV LINKS HERE       */
/* ============================ */
var navLinks = {
  "Home": {
      "isIcon": false,
      "iconSrc": null,
      "href": "#top"
  },
  "Portfolio": {
      "isIcon": false,
      "iconSrc": null,
      "href": "#slide-1"
  },
  "About": {
      "isIcon": false,
      "iconSrc": null,
      "href": "#slide-2"
  },
  "Art": {
      "isIcon": false,
      "iconSrc": null,
      "href": "#slide-3"
  },
  "Menu": {
      "isIcon": true,
      "iconSrc": "./img/ribbon.png",
      "href": "#slide-4"
  }
}

/* ============================ */
/*         HEADERCONTENT        */
/* ============================ */
const HeaderContent = (props) => {
  return (
      <div className="row">
          <div className="grid-container" id="header">
              <div>hi. my name is</div>
              <div id="name">MICAH</div>
              <div>UI / UIX designer <span id="accent">•</span> full stack developer <span id="accent">•</span> digital artist</div>
              <a href="#"><div id="get-started">GET STARTED</div></a>
          </div>
      </div>
  )
}

/* ============================ */
/*              LOGO            */
/* ============================ */
const Logo = (props) => {
  var style = { color: theme["accent1"] }
  var name = "col-" + props.cols;
  return (
      <div className={name} id="logo">
          <span id="logo-0">M</span>
          <span id="logo-1">L</span>
          <span id="logo-2">M</span>
          <span id="logo-3" style={style}><sup>3</sup></span>
      </div>
  )
}

/* ============================ */
/*           NAVLINKS           */
/* ============================ */
const NavLinks = (props) => {
  var name = "col-" + props.cols;
  var gridCols = "auto";  // want to have space for one more space slot than the links; better spacing
  Object.keys(navLinks).forEach(item => gridCols += " auto");
  
  return (
      <div className={name}>
          <div className="grid-container" id="nav-links" style={{gridTemplateColumns: gridCols}}>
              {
                  Object.keys(navLinks).map((key) => {
                      return <a key={key} href={navLinks[key].href} className="nav-link">{key}</a>
                  })
              }
          </div>
      </div>       
  )
}

/* ============================ */
/*              NAVBAR          */
/* ============================ */
const Navbar = (props) => {
  var logoCols = 7;
  var linkCols = 5; // these col numbers added together must equal 12
  return (
      <div className="nav-container" id="nav-container">
          <div className="row" id="navbar">
              <Logo cols={logoCols} />
              <NavLinks cols={linkCols} />
          </div>
      </div>
  )
}

/* ============================ */
/*           HEADER             */
/* ============================ */
class Header extends React.Component{
  render() {
      return(
          <div className="nav" id="nav">
              <Navbar />
              <HeaderContent />
          </div>
      )
  }
}


/* ============================ */
/*           CONTENT            */
/* ============================ */
class App extends React.Component {
  render() {

      return <div className="root-container">
          <a name="top" /><Header />
          <a name="slide-1" /><div className="slide-1" id="slide-1">Slide 1</div>
          <a name="slide-2" /><div className="slide-2" id="slide-2">Slide 2</div>
          <a name="slide-3" /><div className="slide-3" id="slide-3">Slide 3</div>
          <a name="slide-4" /><div className="slide-4" id="slide-4">Slide 4</div>
          <a name="slide-5" /><div className="slide-5" id="slide-5">Slide 5</div>
          <a name="slide-6" /><div className="slide-6" id="slide-6">Slide 6</div>
          <a name="slide-7" /><div className="slide-7" id="slide-7">Slide 7</div>
          <a name="slide-8" /><div className="slide-8" id="slide-8">Slide 8</div>
      </div>
  }
}



export default App;
